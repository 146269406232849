/*   VARIABLES
-------------------------*/
:root {
  --tile-color-old: rgba(169, 169, 169, 0.7);
  --tile-color: rgba(60, 60, 60, 0.7);
  --todo-red: rgba(233, 173, 173, 1);
  --todo-green: rgba(173, 233, 173, 1);
  --todo-blue: rgba(173, 173, 233, 1);
  --cta-hover: rgba(255, 255, 255, 0.5);
  --font-grey: rgb(87, 86, 86);
}




/*   GENERAL STYLES
-------------------------*/
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  text-shadow: 3px 3px 4px rgba(0,0,0,0.2);
}

body {
  margin: 0;
  font-family: 'Commissioner', sans-serif;
  font-size: 1em;
  height: 100vh;
  overflow-x: hidden;
  letter-spacing: 1px;
}

h1,
h2,
h3 {
  font-family: 'Fraunces', serif;
  color: white;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}




/*   INPUT STYLES
-------------------------*/
input {
  position: relative;
  top: -1em;
  padding: 8px 20px;
  width: 44vw;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-radius: 0px;
  box-sizing: border-box;
  background-color: none;
  font-family: 'commissioner', sans-serif;
  font-size: 1em;
  color: black;
  box-shadow: 3px 3px 4px rgba(0,0,0,0.2);
}

textarea:focus, input:focus{
  outline: 2px solid var(--tile-color);
}



/*   HERO STYLES 
-------------------------*/
.hero-image {
/*   background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./images/main-hero.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(5px);
  position: fixed;
  height: 100vh;
  width: 100vw;
}

#sunBG {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./images/sun.jpg");
}

#cloudsBG {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./images/clouds.jpg");
}

#rainBG {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./images/rain.jpg");
}

#snowBG {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./images/snow.jpg");
}

#defaultBG {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./images/main-hero.jpg");
}



/*   CTA STYLES
-------------------------*/
.cta {
  background-color: white;
  color: black;
  padding: 5px 40px;
  font-weight: bold;
  font-family: 'Commissioner', sans-serif;
  text-decoration: none;
}

/* .cta-show {
  color: var(--font-grey);
  position: relative;
  top: .5em;
  font-weight: 500;
} */


/*   HEADER STYLES 
-------------------------*/
.App-header {
  position: absolute;
  top: 8em;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 14px;
  width: 85vw;
}

.grid-item {
  padding: 10px 0 0 0;
  background-color: var(--tile-color);
  box-shadow: 3px 3px 4px rgba(0,0,0,0.2);
}

h1 {
  margin-top: 0;
  margin-bottom: 0em;
  text-decoration: underline;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
}




/*   MAGNUS STYLES 
-------------------------*/
.about {
  position: relative;
  top: -1em;
}

.links {
  position: relative;
  top: -1em;
}

.links a {
  margin-left: 3em;
  margin-right: 3em;
  color: white;
}

.tooltip:hover > a {
  cursor: pointer;
  color: var(--cta-hover);
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -50px;
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}






/* SKILLS, INTERESTS STYLES 
-------------------------*/
.inner-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px; 
  padding: 14px!important;
}

.inner-tile {
  position: relative;
  margin-top: .5em;
  white-space: nowrap;
  padding: 10px;
  background-color: white;
  color: var(--font-grey);
  box-shadow: 3px 3px 4px rgba(0,0,0,0.2);
}


/* #hide {
  display: none;
}

#show {
  display: grid;
} */

.skills-interest-h2 {
  margin-bottom: -.5em;
}

/*   WEATHER STYLES
-------------------------*/
.weather-container {
  position: absolute;
  width: 100vw;
  text-align: center;
  top: .5em;
}

.weather-icon {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  left: 0;
  top: .5em;
  width: 45px;
  margin-right: 2em;
  margin-left: 1.5em;
}

.weather-container p {
  position: relative;
  top: 0;
  left: -1em;
  font-size: 1em;
  font-family: 'commissioner', 'sans-serif';
  color: white;
  letter-spacing: .2em;
  white-space: nowrap;
  font-weight: bold;
  text-shadow: 2px 2px 0px rgba(0,0,0,0.2)!important;
}

.weather-container > * {
  display: inline-block;
}

.weather-temp {
  margin-right: 1.5em;
  font-weight: bold;
}

#city {
  position: absolute;
  width: 85vw;
  top: 4em;
  left: 50%;
  transform: translate(-50%);
}

.bold {
  margin-left: 1em;
}




/*   QUOTE STYLES 
-------------------------*/
.quote-container {
  position: relative;
  margin-bottom: 0;
  color: white;

}




@media only screen and (min-width: 1000px) {
  .grid-container {
    width: 50vw;
  }
  .inner-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .weather-container p {
    letter-spacing: .3em;
    font-size: 1.3em;
  }
  #city {
    width: 50vw;
  }
  .links i {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
}